import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Flatpickr from 'react-flatpickr';
import {German} from 'flatpickr/dist/l10n/de';

import {useMonthDateClassifications} from '../../modules/days/dateClassifications';
import {useDayClasses} from '../../modules/days/dayClasses';
import {ymdToDate} from '../count-aggregator/traffic-data/helpers';

function dateToYmd(date) {
	const offset = date.getTimezoneOffset();
	return new Date(date.getTime() - offset * 60 * 1000)
		.toISOString()
		.split('T')[0];
}

function DateClasses({dateClassMap, date, classes, show}) {
	const valueYmd = date && date instanceof Date ? dateToYmd(date) : null;
	if (!valueYmd || !dateClassMap[valueYmd]) {
		return null;
	}

	return (
		<span className="absolute bottom-0 left-0 right-0 flex justify-center">
			{dateClassMap[valueYmd]
				.map((i) => classes[i])
				.filter((i) => i)
				.map(({id, color, title}) => (
					<span
						key={id}
						className="flex-grow h-1 transition-colors"
						style={{
							background: show
								? color
								: 'rgba(255, 255, 255, 0.0)',
						}}
						title={title}
					/>
				))}
		</span>
	);
}

function MonthCalendar({
	year = null,
	month = null,
	showDateClassesInPicker = false,
	flatpickrProps: {options: flatpickrOptionOverrides, ...flatpickrProps},
} = {}) {
	const classes = useDayClasses();
	const [open, setOpen] = useState(false);
	const [internalValue, setInternalValue] = useState();

	/** @var {Date|null} value */
	const value =
		flatpickrProps.value ||
		internalValue ||
		(flatpickrProps.defaultValue &&
			ymdToDate(flatpickrProps.defaultValue)) ||
		null;

	const [selectedYear, selectYear] = useState(
		/** @returns {number | null} */ () => year,
	);
	const [selectedMonth, selectMonth] = useState(
		/** @returns {number | null} */ () => month,
	);
	const [dateClassMap] = useMonthDateClassifications(
		selectedYear,
		selectedMonth,
	);

	const manipulateDayElementRef = useRef();
	manipulateDayElementRef.current = useCallback(
		function manipulateDayElement(date, element) {
			const ymd = dateToYmd(date);

			if (classes && dateClassMap[ymd]) {
				let html = '';

				dateClassMap[ymd]
					.map((id) => classes[id])
					.filter((i) => i)
					.forEach((dayClass) => {
						html +=
							'<span class="flex-grow opacity-90" style="background-color: ' +
							dayClass.color +
							'" title="' +
							dayClass.title +
							'"></span>';
					});

				element.innerHTML =
					'' +
					'<span class="absolute inset-0 z-0 flex flex-1 rounded-full overflow-hidden">' +
					html +
					'</span>' +
					'<span class="absolute inset-0 z-10 flex justify-center items-center">' +
					'<span class="block p-0.5 bg-gray-200 bg-opacity-80 rounded-sm leading-none">' +
					element.innerHTML +
					'</span>' +
					'</span>';
			}
		},
		[classes, dateClassMap],
	);

	const instanceRef = useRef();
	const flatpickrOptions = useMemo(() => {
		const overrides = flatpickrOptionOverrides || {};

		return {
			locale: German,
			altInput: true,
			ariaDateFormat: 'l, d.m.Y',
			allowInput: true,
			disableMobile: true,
			time_24hr: true,
			weekNumbers: true,
			onDayCreate: function (dObj, dStr, fp, dayElem) {
				manipulateDayElementRef.current(dayElem.dateObj, dayElem);

				if (overrides.onDayCreate) {
					overrides.onDayCreate(dObj, dStr, fp, dayElem);
				}
			},
			...overrides,
			onMonthChange: function (selectedDates, dateStr, instance) {
				console.log('ready', {curMon: instance.currentMonth});
				selectMonth(instance.currentMonth + 1); // month 0-11

				if (overrides.onMonthChange) {
					overrides.onMonthChange(selectedDates, dateStr, instance);
				}
			},
			onYearChange: function (selectedDates, dateStr, instance) {
				selectYear(instance.currentYear);

				if (overrides.onYearChange) {
					overrides.onYearChange(selectedDates, dateStr, instance);
				}
			},
			onReady: function (selectedDates, dateStr, instance) {
				instanceRef.current = instance;

				if (overrides.onReady) {
					overrides.onReady(selectedDates, dateStr, instance);
				}
			},
		};
	}, [flatpickrOptionOverrides, selectYear, selectMonth]);

	useEffect(
		function () {
			const instance = instanceRef.current;
			if (instance && dateClassMap) {
				instance.redraw();
			}
		},
		[dateClassMap],
	);

	const onOpen = useCallback(
		(selectedDates, dateStr, instance) => {
			setOpen(true);

			if (flatpickrProps.onOpen) {
				flatpickrProps.onOpen(selectedDates, dateStr, instance);
			}
		},
		[setOpen, flatpickrProps],
	);

	const onClose = useCallback(
		(selectedDates, dateStr, instance) => {
			setOpen(false);

			if (flatpickrProps.onClose) {
				flatpickrProps.onClose(selectedDates, dateStr, instance);
			}
		},
		[setOpen, flatpickrProps],
	);

	const onChange = useCallback(
		(selectedDates, dateStr, instance) => {
			setInternalValue(selectedDates[0]);

			if (flatpickrProps.onChange) {
				flatpickrProps.onChange(selectedDates, dateStr, instance);
			}
		},
		[setInternalValue, flatpickrProps],
	);

	useEffect(() => {
		if (value instanceof Date) {
			selectYear(value.getFullYear());
			selectMonth(value.getMonth() + 1);
		}
	}, [value]);

	return (
		<span className="h-full inline-block relative">
			<Flatpickr
				{...flatpickrProps}
				onOpen={onOpen}
				onClose={onClose}
				onChange={onChange}
				options={{
					...flatpickrOptions,
					altFormat: open ? 'd.m.y' : 'D, d.m.y',
				}}
			/>

			{showDateClassesInPicker && (
				<DateClasses
					dateClassMap={dateClassMap}
					date={value}
					classes={classes}
					show={!open}
				/>
			)}
		</span>
	);
}

export default MonthCalendar;
