import React from 'react';
//import {ReactQueryDevtools as BaseReactQueryDevtools} from 'react-query/devtools';

function ReactQueryDevtools() {
	return (
		<React.Fragment /> // <BaseReactQueryDevtools initialIsOpen={false} />
	);
}

export default ReactQueryDevtools;
