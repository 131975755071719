import axios from 'axios';
import {useCallback} from 'react';
import {useQuery, useMutation, useQueryClient} from 'react-query';

export async function fetchUserPreferences() {
	const response = await axios('/msp/user/preferences/gui/get');
	return response.data;
}

export async function setUserPreference(key, value) {
	const response = await axios.post('/msp/user/preferences/gui/set', {
		key: key,
		value: value,
	});
	return response.data;
}

export function useSidebarPreference() {
	const queryClient = useQueryClient();
	const {isLoading, isError, data} = useQuery(
		'user-preferences',
		fetchUserPreferences,
		{
			// eslint-disable-next-line @neonaut/no-restricted-global-extend
			initialData: window.MS_PLATFORM?.USER_PREFERENCES || {},
			staleTime: 1000,
		},
	);
	const sidebarOpenMutation = useMutation(
		({open}) => setUserPreference('sidebarOpen', open),
		{
			onMutate: async ({open}) => {
				await queryClient.cancelQueries('user-preferences');
				queryClient.setQueryData('user-preferences', (old) => ({
					...old,
					sidebarOpen: open,
				}));
			},
			onSettled: async () => {
				await queryClient.invalidateQueries('user-preferences');
			},
		},
	);
	const sidebarOpen = isLoading || isError || data?.sidebarOpen;
	const toggle = useCallback(
		() => sidebarOpenMutation.mutate({open: !sidebarOpen}),
		[sidebarOpen, sidebarOpenMutation],
	);

	return [sidebarOpen, toggle];
}
