import React, {useCallback, useEffect, useRef, useState} from 'react';
import createEventHandlerMatching from '@neonaut/lib-js/es/dom/events/create-event-handler-matching';

import {autoRenderComponents} from '../../modules/autoRenderComponents';
import {render as autoConfirmButton} from '../../modules/confirmButton';

import Modal from './Modal';

function AutoModal({
	label,
	id,
	html,
	isOpen: initialIsOpen,
	onOpen,
	onClose,
	onAfterOpen,
	modalOptions = {},
}) {
	const contentRef = useRef();
	const [modalIsOpen, setIsOpen] = useState(initialIsOpen);

	const openModal = useCallback(
		function () {
			if (onOpen) {
				onOpen({id: id, contentElement: contentRef.current});
			}
			setIsOpen(true);
		},
		[id, onOpen, setIsOpen],
	);

	const closeModal = useCallback(
		function () {
			if (onClose) {
				onClose({id: id, contentElement: contentRef.current});
			}
			setIsOpen(false);
		},
		[id, onClose, setIsOpen],
	);

	const handleAfterOpenModal = useCallback(
		function afterOpenModal() {
			if (onAfterOpen) {
				onAfterOpen({id: id, contentElement: contentRef.current});
			}

			autoRenderComponents(contentRef.current, true);
			autoConfirmButton(contentRef.current);
		},
		[id, onAfterOpen],
	);

	useEffect(
		function attachGlobalEvents() {
			const handleClick = createEventHandlerMatching(
				'[data-toggle="msp-modal"][data-target="#' +
					id +
					'"], [data-toggle="msp-modal"][href="#' +
					id +
					'"]',
				function (e) {
					e.preventDefault();

					if (modalIsOpen) {
						closeModal();
					} else {
						openModal();
					}
				},
			);

			document.addEventListener('click', handleClick);
			return () => {
				document.removeEventListener('click', handleClick);
			};
		},
		[id, openModal, closeModal, modalIsOpen],
	);

	return (
		<Modal
			{...modalOptions}
			isOpen={modalIsOpen}
			onAfterOpen={handleAfterOpenModal}
			onRequestClose={closeModal}
			contentLabel={label}
		>
			<div ref={contentRef} dangerouslySetInnerHTML={{__html: html}} />
		</Modal>
	);
}

export default AutoModal;
