import React, {useCallback, useState} from 'react';
import axios from 'axios';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useDropzone} from 'react-dropzone';

import Button from './Button';

function Attachments({route, uploadRoute, deleteRoute}) {
	const [id] = useState(() => 'msp-attachments-file-' + Date.now());

	const queryClient = useQueryClient();
	const queryKey = 'msp-attachments:' + route;

	const invalidate = useCallback(() => {
		queryClient.invalidateQueries(queryKey);
	}, [queryClient, queryKey]);

	const [progress, setProgress] = useState(0);
	const uploadAttachment = useMutation(
		({data}) =>
			axios.post(uploadRoute, data, {
				headers: {'Content-Type': 'multipart/form-data'},
				onUploadProgress: (ev) =>
					setProgress(Math.round((ev.loaded * 100) / ev.total)),
			}),
		{onSuccess: invalidate},
	);

	const deleteAttachment = useMutation(
		({data}) =>
			axios.delete(deleteRoute, {
				data: {file: data.get('file')},
			}),
		{onSuccess: invalidate},
	);

	const {getRootProps, getInputProps, isDragActive} = useDropzone({
		onDropAccepted: useCallback(
			(acceptedFiles) => {
				const data = new FormData();
				acceptedFiles.forEach((file) => {
					data.append('file[]', file, file.name);
				});
				uploadAttachment.mutate({data: data});
			},
			[uploadAttachment],
		),
	});

	const {isLoading, isRefetching, isError, data} = useQuery(
		queryKey,
		async () => (await axios(route)).data,
		{
			refetchOnWindowFocus: false,
		},
	);
	const lockUi =
		isRefetching ||
		deleteAttachment.isLoading ||
		uploadAttachment.isLoading;

	const handleSubmit = useCallback(
		(e) => {
			e.preventDefault();
			uploadAttachment.mutate({data: new FormData(e.target)});
		},
		[uploadAttachment],
	);

	const handleDelete = useCallback(
		(e) => {
			e.preventDefault();
			deleteAttachment.mutate({data: new FormData(e.target)});
		},
		[deleteAttachment],
	);

	return (
		<div
			style={
				lockUi
					? {pointerEvents: 'none', opacity: '0.8', cursor: 'wait'}
					: {}
			}
		>
			<h3 className="mt-8 mb-2 font-bold leading-tight">Anhänge</h3>

			<p className="text-gray-700 text-center leading-loose">
				{isLoading ? 'Lädt Anhänge …' : ''}
				{isError ? 'Fehler!' : ''}
				{!isLoading && !isError && !data?.attachments
					? '- Keine Anhänge -'
					: ''}
			</p>

			{data?.attachments?.map((attachment) => (
				<div
					key={attachment.name}
					className="flex w-full text-sm border-t"
				>
					<a
						href={attachment.url}
						rel="download"
						target="_blank"
						className="flex-grow py-2 hover:underline overflow-hidden overflow-ellipsis"
					>
						{attachment.name}
					</a>

					<form onSubmit={handleDelete}>
						<input
							type="hidden"
							name="file"
							value={attachment.name}
						/>
						<Button className="my-0.5">
							<span className="sr-only">Datei löschen</span>

							{/* TODO @typo3/icons actions-delete */}
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 16 16"
								className="inline-block fill-current w-4 h-4"
							>
								<path d="M7 5H6v8h1zm3 0H9v8h1z" />
								<path d="M13 3h-2v-.75C11 1.56 10.44 1 9.75 1h-3.5C5.56 1 5 1.56 5 2.25V3H3v10.75c0 .69.56 1.25 1.25 1.25h7.5c.69 0 1.25-.56 1.25-1.25V3zm-7-.75A.25.25 0 016.25 2h3.5a.25.25 0 01.25.25V3H6v-.75zm6 11.5a.25.25 0 01-.25.25h-7.5a.25.25 0 01-.25-.25V4h8v9.75z" />
								<path d="M13.5 4h-11a.5.5 0 010-1h11a.5.5 0 010 1z" />
							</svg>
						</Button>
					</form>
				</div>
			))}

			<form onSubmit={handleSubmit}>
				<div
					{...getRootProps()}
					className={`flex items-center justify-center mt-5 mb-3 px-2 py-1 w-full text-sm cursor-pointer border rounded ${
						isDragActive ? 'bg-blue-300' : 'bg-gray-100'
					}`}
					style={{minHeight: 50}}
				>
					<label htmlFor={id} className="sr-only">
						Datei
					</label>
					<input id={id} name="file[]" {...getInputProps()} />

					<div>
						{/* TODO @typo3/icons actions-upload */}
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 16 16"
							className="inline-block fill-current mr-1 w-4 h-4"
						>
							<path d="M10 11h1v1h-1zm2 0h1v1h-1zm-.73-5H4.73a.25.25 0 01-.188-.414l3.27-3.743a.244.244 0 01.377 0l3.27 3.743A.25.25 0 0111.27 6z" />
							<path d="M14.5 9H10v1h4v3H2v-3h4V9H1.5a.5.5 0 00-.5.5v4a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-4a.5.5 0 00-.5-.5z" />
							<path d="M7 6h2v4H7z" />
						</svg>

						<span>
							{isDragActive
								? 'Datei hier fallen lassen'
								: 'Datei hochladen …'}
							{uploadAttachment.isLoading
								? ` (${progress} %)`
								: ''}
						</span>
					</div>
				</div>
			</form>
		</div>
	);
}

export default Attachments;
