import axios from 'axios';
import {useCallback} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';

export function createFetchDateClassificationsForMonth(
	year = null,
	month = null,
) {
	return async function fetchDateClassificationsForMonth() {
		if (year === null || month === null) {
			return Promise.resolve([]);
		}

		const response = await axios(
			`/msp/days/date-classification/${year}/${month}.json`,
		);
		return response.data?.data;
	};
}

export function createSetDateClassificationsForMonth(year, month) {
	return async function setDateClassificationsForMonth({dateMap}) {
		const response = await axios.post(
			`/msp/days/date-classification/${year}/${month}.json`,
			dateMap,
		);
		return response.data;
	};
}

export function useMonthDateClassifications(year, month) {
	const queryKey = `days.dateClassification.${year}.${month}`;
	const queryClient = useQueryClient();
	const {isLoading, isError, data} = useQuery(
		queryKey,
		createFetchDateClassificationsForMonth(year, month),
		{
			//staleTime: 1000,
		},
	);
	const mutation = useMutation(
		createSetDateClassificationsForMonth(year, month),
		{
			onMutate: async ({dateMap}) => {
				await queryClient.cancelQueries(queryKey);
				queryClient.setQueryData(queryKey, (_old) => dateMap);
			},
		},
	);
	const value = isLoading || isError || data;
	const set = useCallback(
		(dateMap) => mutation.mutate({dateMap: dateMap}),
		[mutation],
	);

	return [value, set];
}
