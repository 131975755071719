import React, {useCallback, useMemo, useState} from 'react';

import Flatpickr from 'react-flatpickr';
import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate';
import {German} from 'flatpickr/dist/l10n/de';

function DateTimePicker({
	defaultValue = null,
	defaultHour = 7,
	minDate,
	maxDate,
	enableTime,
	...props
}) {
	const [value, setValue] = useState(defaultValue);
	const handleUpdate = useCallback(([date]) => setValue(date), [setValue]);
	const options = useMemo(
		function () {
			const nextOptions = {
				locale: German,
				altInput: true,
				altFormat: 'd.m.Y',
				ariaDateFormat: 'd.m.Y',
				allowInput: true,
				disableMobile: true,
				maxDate: maxDate,
				minDate: minDate,
				weekNumbers: false,
				appendTo:
					document.getElementById('js-msp-flatpickr-root') ||
					document.body,
				plugins: [
					new ConfirmDatePlugin({
						showAlways: true,
						confirmText: 'OK ',
					}),
				],
			};

			if (enableTime) {
				Object.assign(nextOptions, {
					altFormat: 'd.m.Y H:i',
					ariaDateFormat: 'd.m.Y H:i',
					enableTime: true,
					time_24hr: true,
					minuteIncrement: 15,
					defaultHour: defaultHour,
					defaultMinute: 0,
				});
			}

			return nextOptions;
		},
		[maxDate, minDate, enableTime, defaultHour],
	);

	return (
		<Flatpickr
			value={value}
			onChange={handleUpdate}
			key={`enable-time-${enableTime ? 'true' : 'false'}`}
			options={options}
			className="form-input"
			{...props}
		/>
	);
}

export default DateTimePicker;
