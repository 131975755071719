import createEventHandlerMatching from '@neonaut/lib-js/es/dom/events/create-event-handler-matching';

export function renderSingle(element, {onChange = null} = {}) {
	//let isCollapsed = containerElement.classList.contains('msp-collapsible--collapsed');
	const clickListener = createEventHandlerMatching(
		'.js-msp-collapsible-toggle',
		function () {
			toggle();
		},
	);

	function toggle() {
		element.classList.toggle('msp-collapsible--collapsed');
		onChange();
	}

	element.addEventListener('click', clickListener);

	return function () {
		element.removeEventListener('click', clickListener);
	};
}

export function render(containerElement = document.body, options = {}) {
	containerElement
		.querySelectorAll('.js-msp-collapsible')
		.forEach((element) => renderSingle(element, options));
}
