import React, {useEffect} from 'react';

import {useSidebarPreference} from '../../modules/userPreferences';

function SidebarEntry({url, label, icon, isActive, iconOnly}) {
	let classes =
		'flex mx-1 my-1 px-2 py-1 items-center rounded hover:bg-gray-800 focus:text-white focus:outline-none focus:ring-2 focus:border-transparent transition-all';
	classes += isActive ? ' bg-msgRed' : ' bg-gray-700';

	return (
		<li>
			<a href={url} className={classes} title={label}>
				<span
					className={`msp-sidebar__icon msp-sidebar__icon--${
						iconOnly ? 'icon-only' : 'with-text'
					}`}
					dangerouslySetInnerHTML={{__html: icon}}
				/>

				<span className={`ml-2 truncate ${iconOnly ? 'sr-only' : ''}`}>
					{label}
				</span>
			</a>
		</li>
	);
}

function Sidebar({...props}) {
	const {entries} = props;
	const [sidebarOpen] = useSidebarPreference();

	useEffect(() => {
		document.documentElement.classList[sidebarOpen ? 'add' : 'remove'](
			'msp--sidebar-open',
		);
	}, [sidebarOpen]);

	return (
		<div
			className="msp-sidebar flex-shrink-0 bg-gray-700 text-white py-4 text-sm sticky top-12 overflow-x-hidden overflow-y-auto"
			style={{
				height: 'calc(100vh - 3rem)',
				width: `${sidebarOpen ? 15 : 4}rem`,
			}}
		>
			<div style={{width: '15rem'}}>
				<ul>
					{entries.map((entry) => (
						<SidebarEntry
							key={entry.id}
							{...entry}
							iconOnly={!sidebarOpen}
						/>
					))}
				</ul>
			</div>
		</div>
	);
}

export default Sidebar;
