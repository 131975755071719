import React from 'react';

export default function Button({children, className, ...attrs}) {
	className =
		'rounded border px-2 py-1 hover:bg-gray-100 active:bg-gray-200 focus:outline-none focus:border-gray-200 focus:ring ' +
		'ring-gray-300 disabled:opacity-25 transition ease-in-out duration-150 ' +
		className;

	return (
		<button type="submit" {...attrs} className={className}>
			{children}
		</button>
	);
}
