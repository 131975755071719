/** @param {Date} date */
export function dateToYmd(date) {
	const year = date.getFullYear();

	let month = '' + (date.getMonth() + 1);
	if (month.length < 2) {
		month = '0' + month;
	}

	let day = '' + date.getDate();
	if (day.length < 2) {
		day = '0' + day;
	}

	return [year, month, day].join('-');
}

/**
 * @param {string} input yyyy-mm-dd string
 * @returns {Date}
 */
export function ymdToDate(input) {
	const [y, m, d] = input.split('-').map((part) => parseInt(part, 10));
	return new Date(y, m - 1, d); // NOTE: months are 0-based
}

/**
 * @param {number} [month]
 * @param {number} [year]
 * @returns {Date}
 */
export function getFirstDayOfMonth(
	month = new Date().getMonth() + 1,
	year = new Date().getFullYear(),
) {
	return new Date(year, month - 1);
}

/**
 * @param {number} [month]
 * @param {number} [year]
 * @returns {Date}
 */
export function getLastDayOfMonth(
	month = new Date().getMonth() + 1,
	year = new Date().getFullYear(),
) {
	const date = new Date(year, month - 1 + 1, 0, 12, 0, 0, 0);
	return new Date(year, month - 1, date.getDate());
}

export function getFirstDayOfYear() {
	const currYear = new Date().getFullYear();
	return new Date(currYear, 0);
}

export function getLastDayOfYear() {
	const currYear = new Date().getFullYear();
	return new Date(currYear, 11, 31);
}

export function getFirstDayOfLastYear() {
	const currYear = new Date().getFullYear();
	return new Date(currYear - 1, 0);
}

export function getLastDayOfLastYear() {
	const currYear = new Date().getFullYear();
	return new Date(currYear - 1, 11, 31);
}
