import React, {useCallback, useState} from 'react';

import Modal from './Modal';

/**
 * @param {{
 *   text: React.ReactNode,
 *   yesText?: React.ReactNode,
 *   noText?: React.ReactNode,
 *   onAction: Function
 * }} props props
 * @returns {React.Element} element
 */
function ConfirmDialog({
	text,
	title = 'Bestätigung',
	yesText = 'Ok',
	noText = 'Abbrechen',
	onAction,
}) {
	const [isOpen, setIsOpen] = useState(true);
	const handleYes = useCallback(() => {
		setIsOpen(false);
		onAction(true);
	}, [onAction]);
	const handleNo = useCallback(() => {
		setIsOpen(false);
		onAction(false);
	}, [onAction]);

	return (
		<Modal isOpen={isOpen} onRequestClose={handleNo}>
			<h2 className="mb-4 text-xl font-bold leading-tight">
				Bestätigung
			</h2>

			<div className="text-lg">{text}</div>

			<div className="border-t mt-4 pt-4 flex justify-end">
				<button
					type="button"
					className="px-2 py-0.5 bg-gray-400 text-black text-bold rounded mx-2"
					onClick={handleNo}
				>
					{noText}
				</button>
				<button
					type="button"
					autoFocus={true}
					className="px-2 py-0.5 bg-gray-800 text-white text-bold rounded mx-2"
					onClick={handleYes}
				>
					{yesText}
				</button>
			</div>
		</Modal>
	);
}

export default ConfirmDialog;
