import React, {useCallback, useState} from 'react';

import {useDayClasses} from '../../modules/days/dayClasses';

import EditMonthCalendar from './EditMonthCalendar';

function ToolButton({name, mode, setMode, ...props}) {
	const isActive = mode === name;
	const handleClick = useCallback(() => {
		setMode(isActive ? null : name);
	}, [isActive, name, setMode]);

	return (
		<button
			type="button"
			onClick={handleClick}
			className={`mr-2 px-2 py-0.5 rounded-lg border border-gray-200 bg-gray-200 transition-opacity ${
				mode === name
					? 'border-gray-400 opacity-100 underline'
					: 'opacity-80'
			}`}
			{...props}
		/>
	);
}

function EditYearCalendar({year}) {
	const classes = useDayClasses();
	const [mode, setMode] = useState(null);

	return (
		<div>
			<div className="sticky top-12 bg-gray-200 w-auto text-center shadow z-10 py-1 px-4">
				<ToolButton name="unset" mode={mode} setMode={setMode}>
					Entfernen
				</ToolButton>

				{Object.values(classes).map((dayClass) => (
					<ToolButton
						key={dayClass.id}
						style={{background: dayClass.color}}
						name={dayClass.id}
						mode={mode}
						setMode={setMode}
					>
						{dayClass.title}
					</ToolButton>
				))}
			</div>

			<div className="msp-days-month-calendar p-10 flex flex-wrap justify-center flex-1 gap-5">
				{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => (
					<EditMonthCalendar
						key={month}
						year={year}
						month={month}
						mode={mode}
					/>
				))}
			</div>
		</div>
	);
}

export default EditYearCalendar;
