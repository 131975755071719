import React from 'react';
import ReactDOM from 'react-dom';

import AutoModal from '../components/core/AutoModal';

const prefix = 'msp-modal';
let globalIdCounter = 0;

export function createRenderTarget() {
	const renderTarget = document.createElement('div');
	renderTarget.hidden = true;
	document.body.append(renderTarget);

	return renderTarget;
}

export function parseModalTemplate(modalElement) {
	let id = modalElement.getAttribute('id');
	if (!id) {
		id = `${prefix}-${globalIdCounter++}`;
		modalElement.setAttribute('id', id);
	}

	const html = modalElement.innerHTML;
	const label = modalElement.getAttribute('aria-label');
	const isOpen = modalElement.getAttribute('data-msp-modal-show') === 'true';
	modalElement.remove();

	return {id: id, html: html, label: label, isOpen: isOpen};
}

function renderAutoModal(modalElement) {
	const {id, html, label, isOpen} = parseModalTemplate(modalElement);

	ReactDOM.render(
		<AutoModal id={id} html={html} label={label} isOpen={isOpen} />,
		createRenderTarget(),
	);
}

export function render(container = document.body) {
	container.querySelectorAll('.js-msp-modal').forEach(renderAutoModal);
}
