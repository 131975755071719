import React, {useMemo} from 'react';

import MonthCalendar from './MonthCalendar';

function StandaloneDateSelector({date}) {
	const flatpickrProps = useMemo(
		() => ({
			name: 'date',
			className: 'form-input border-0 p-1 w-28 text-left',
			options: {
				//maxDate: new Date(),
				appendTo:
					document.getElementById('js-msp-flatpickr-root') ||
					document.body,
			},
			defaultValue: date,
			onChange: (_, __, {input}) => {
				if (input.value !== date) {
					const form = input.closest('form');
					if (form) {
						form.submit();
					}
				}
			},
		}),
		[date],
	);

	return (
		<MonthCalendar
			flatpickrProps={flatpickrProps}
			showDateClassesInPicker={true}
		/>
	);
}

export default StandaloneDateSelector;
