import React from 'react';
import ReactDOM from 'react-dom';

import ConfirmDialog from '../components/core/ConfirmDialog';

let lastAbort = null;

let renderTarget;

export function getRenderTarget() {
	if (!renderTarget) {
		renderTarget = document.createElement('div');
		renderTarget.hidden = true;
		document.body.append(renderTarget);
	}

	return renderTarget;
}

/**
 * @param {{
 *   text: React.ReactNode,
 *   yesText?: React.ReactNode,
 *   noText?: React.ReactNode
 * }} options options
 * @returns {Promise<boolean>} promise
 */
export default function confirm({text, yesText, noText}) {
	if (lastAbort) {
		lastAbort();
		lastAbort = null;
	}

	return new Promise((resolve) => {
		function onAction(res) {
			lastAbort = null;
			resolve(res);
		}

		lastAbort = () => onAction(false);

		// eslint-disable-next-line react/jsx-no-bind
		ReactDOM.render(
			<ConfirmDialog
				text={text}
				yesText={yesText}
				noText={noText}
				onAction={onAction}
				key={Date.now()}
			/>,
			getRenderTarget(),
		);
	});
}
