import React from 'react';
import ReactDOM from 'react-dom';

import {QueryClient, QueryClientProvider} from 'react-query';

window.MS_PLATFORM = window.MS_PLATFORM || {};
window.MS_PLATFORM.__autoRender_queryClient__ =
	window.MS_PLATFORM.__autoRender_queryClient__ || new QueryClient();

function ComponentRoot({children}) {
	return (
		<QueryClientProvider
			client={window.MS_PLATFORM.__autoRender_queryClient__}
		>
			{children}
		</QueryClientProvider>
	);
}

export function autoRenderComponent(
	container,
	className,
	Component,
	baseProps = {},
) {
	[...container.getElementsByClassName(className)].forEach(
		/** @param {HTMLElement} rootElement root element */ (rootElement) => {
			const props = {...baseProps};
			const data = rootElement.getAttribute('data-props');
			if (data) {
				try {
					Object.assign(props, JSON.parse(data));
				} catch (e) {
					// why are we rendering without any expected (!!!) data here?
					console.error(e);
				}
			}

			if (
				rootElement.getAttribute('data-provide-original-html') ===
				'true'
			) {
				props.originalHTML = '' + rootElement.innerHTML;
			}

			const comp = (
				<ComponentRoot>
					<Component {...props} />
				</ComponentRoot>
			);

			ReactDOM.render(comp, rootElement);
		},
	);
}

window.MS_PLATFORM = window.MS_PLATFORM || {};
window.MS_PLATFORM.__autoRender_components__ =
	window.MS_PLATFORM.__autoRender_components__ || {};

/** @param {{className: string, Component: unknown}} def */
export function addAutoRenderComponent(def) {
	window.MS_PLATFORM.__autoRender_components__[def.className] = def;
}

export function autoRenderComponents(container = document, partial = false) {
	Object.keys(window.MS_PLATFORM.__autoRender_components__).forEach(
		(className) => {
			const {Component, renderPartial, baseProps} =
				window.MS_PLATFORM.__autoRender_components__[className];
			if (!partial || renderPartial !== false) {
				autoRenderComponent(container, className, Component, baseProps);
			}
		},
	);
}
