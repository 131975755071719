import React, {Fragment, memo} from 'react';

import BaseModal from '../../modules/modal';

const CLOSE_BUTTON_CONTENT = (
	<Fragment>
		<svg
			className="w-12 h-12"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 16 16"
		>
			<path d="M11.9 5.5L9.4 8l2.5 2.5c.2.2.2.5 0 .7l-.7.7c-.2.2-.5.2-.7 0L8 9.4l-2.5 2.5c-.2.2-.5.2-.7 0l-.7-.7c-.2-.2-.2-.5 0-.7L6.6 8 4.1 5.5c-.2-.2-.2-.5 0-.7l.7-.7c.2-.2.5-.2.7 0L8 6.6l2.5-2.5c.2-.2.5-.2.7 0l.7.7c.2.2.2.5 0 .7z" />
		</svg>

		<span className="sr-only">Schließen</span>
	</Fragment>
);

/** @param {{header?: React.ReactNode} & BaseModal.Props} props */
function Modal({header, children, onRequestClose, ...props}) {
	return (
		<BaseModal
			portalClassName="msp-modal-portal"
			overlayClassName={{
				base: 'msp-modal-overlay',
				afterOpen: 'msp-modal-overlay--after-open',
				beforeClose: 'msp-modal-overlay--before-close',
			}}
			className={{
				base: 'msp-modal',
				afterOpen: 'msp-modal--after-open',
				beforeClose: 'msp-modal--before-close',
			}}
			bodyOpenClassName="msp--modal-open"
			htmlOpenClassName="msp--modal-open"
			closeTimeoutMS={0}
			onRequestClose={onRequestClose}
			{...props}
		>
			{header === undefined && (
				<button
					className="float-right w-12 h-12"
					onClick={onRequestClose}
				>
					{/* float only if there is no header */}
					{CLOSE_BUTTON_CONTENT}
				</button>
			)}

			{header !== undefined && (
				<div
					style={{display: 'flex', flexDirection: 'row'}}
					className="h-12"
				>
					<div style={{flexGrow: 1}}>{header}</div>

					<button
						style={{flexShrink: 1}}
						className="w-12 h-12"
						onClick={onRequestClose}
					>
						{CLOSE_BUTTON_CONTENT}
					</button>
				</div>
			)}

			<div className="p-4">{children}</div>
		</BaseModal>
	);
}

export default memo(Modal);
