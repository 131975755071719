import confirm from './confirm';

function renderConfirmButton(elem) {
	const confirmText =
		elem.getAttribute('data-msp-confirm-text') || 'Wirklich löschen?';
	const yesText = elem.getAttribute('data-msp-confirm-yes-text') || 'Ja';
	const noText = elem.getAttribute('data-msp-confirm-no-text') || 'Abbrechen';

	elem.addEventListener('click', async (e) => {
		e.preventDefault();

		try {
			const confirmed = await confirm({
				text: confirmText,
				yesText: yesText,
				noText: noText,
			});
			if (confirmed) {
				e.target.closest('form').submit();
			}
		} catch (err) {
			console.error(err);
		}
	});
}

export function render(container = document) {
	container
		.querySelectorAll('.js-msp-confirm-button')
		.forEach(renderConfirmButton);
}
