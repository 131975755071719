import './modules/setupAxios';
import {
	addAutoRenderComponent,
	autoRenderComponents,
} from './modules/autoRenderComponents';
import {render as autoModals} from './modules/autoModals';
import {render as autoCollapsible} from './modules/collapsible';
import {render as autoConfirmButton} from './modules/confirmButton';

import Attachments from './components/core/Attachments';
import Sidebar from './components/core/Sidebar';
import ReactQueryDevtools from './components/core/ReactQueryDevtools';
import SidebarToggle from './components/core/SidebarToggle';
import DateTimePicker from './components/core/DateTimePicker';
import StandaloneDateSelector from './components/days/StandaloneDateSelector';
import EditYearCalendar from './components/days/EditYearCalendar';

// layout/global
addAutoRenderComponent({
	className: 'js-msp-sidebar',
	Component: Sidebar,
	renderPartial: false,
});
addAutoRenderComponent({
	className: 'js-msp-react-query-devtools',
	Component: ReactQueryDevtools,
	renderPartial: false,
});

// core
addAutoRenderComponent({
	className: 'js-msp-sidebar-toggle',
	Component: SidebarToggle,
});
addAutoRenderComponent({
	className: 'js-msp-date-time-picker',
	Component: DateTimePicker,
});
addAutoRenderComponent({
	className: 'js-msp-attachments',
	Component: Attachments,
});

// days
addAutoRenderComponent({
	className: 'js-msp-days-date-selector',
	Component: StandaloneDateSelector,
});
addAutoRenderComponent({
	className: 'js-msp-days-edit-year-calendar',
	Component: EditYearCalendar,
});

autoRenderComponents();
autoModals();
autoCollapsible();
autoConfirmButton();
