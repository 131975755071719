import axios from 'axios';
import {useQuery} from 'react-query';

export async function fetchDayClasses() {
	const response = await axios('/msp/days/day-classes.json');
	return response.data;
}

export function useDayClasses() {
	const queryKey = 'days.dayClasses';
	const {isLoading, isError, data} = useQuery(queryKey, fetchDayClasses);
	return isLoading || isError || data?.data;
}
