import React, {useCallback, useMemo, useRef} from 'react';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';

import {useMonthDateClassifications} from '../../modules/days/dateClassifications';

import MonthCalendar from './MonthCalendar';

function EditMonthCalendar({year, month, mode}) {
	const [dateClassMap, setDateClassMap] = useMonthDateClassifications(
		year,
		month,
	);

	const handleSelect = useCallback(
		function (dateStr) {
			if (!mode) {
				return;
			}

			if (mode === 'unset') {
				setDateClassMap({
					...dateClassMap,
					[dateStr]: null,
				});
				return;
			}

			const base = dateClassMap[dateStr] || [];
			setDateClassMap({
				...dateClassMap,
				[dateStr]: base.includes(mode)
					? base.filter((a) => a !== mode)
					: [...base, mode],
			});
		},
		[mode, dateClassMap, setDateClassMap],
	);

	const onSelectRef = useRef();
	onSelectRef.current = handleSelect;

	const flatpickrProps = useMemo(
		() => {
			const minDate = new Date(
				`${year}-${('' + month).padStart(2, '0')}-01`,
			);

			return {
				className: 'hidden', // hide <input>
				onChange: function (selectedDates, dateStr, instance) {
					if (dateStr) {
						instance.clear();
						onSelectRef.current(dateStr);
					}
				},
				options: {
					static: true,
					inline: true,
					showMonths: 1,
					monthSelectorType: 'static',
					minDate: minDate,
					maxDate: lastDayOfMonth(minDate),
				},
			};
		},
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
		[mode, year, month],
	);

	return (
		<div className="msp-flatpickr-theme--light msp-flatpickr-theme--light--inline msp-flatpickr-theme--light--fixed-month-no-year">
			<MonthCalendar
				year={year}
				month={month}
				flatpickrProps={flatpickrProps}
			/>
		</div>
	);
}

export default EditMonthCalendar;
